.display-inline-flex{
    display: inline-flex;
}
.year{
    font-size: 40px;
    font-weight: 700;
    border-bottom: 1px solid #6b6868;
    text-align: left;
    margin-left: 1.7rem;
    /* position: sticky !important;
    top: 90px;
    z-index: 100;
    background-color: '#f7f7f7' */
}
.year-current{
    color:red;
    font-size: 40px;
    font-weight: 700;
    border-bottom: 1px solid #6b6868;
    text-align: left;
    margin-left: 1.7rem;
    /* position: sticky !important;
    top: 90px;
    z-index: 100;
    background-color: '#f7f7f7' */
}
.my-curDay{
   color: red
}

.flex{
    display: flex;
    /*flex-wrap: wrap;*/
}
.grid{
    display: grid;
    margin-bottom: 10px;

}
.flex-month {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 10px;

    /*Animation try*/
    /*background-color: white;*/
    /*-webkit-animation-name: example; !* Safari 4.0 - 8.0 *!*/
    /*-webkit-animation-duration: 4s; !* Safari 4.0 - 8.0 *!*/
    /*animation-name: example;*/
    /*animation-duration: 2s;*/
}
.transform {
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -o-transition: all 2s ease;
    -ms-transition: all 2s ease;
    transition: all 1s ease-out;
}

.transform-active {
    transition: all 2s ease-out;
    transform  : scaleX(1);
    /*background-color: #45CEE0;*/
    height: 200px;
    width: 200px;
}

table{
    table-layout: fixed;
}
table td{
    text-align: center;
}
table th{
    text-align: center;
}
.weekday-shortnames{
    font-size: 10px;
    padding: 0 !important;
}
header{
    height: 63px;
}

.weekend-sticky{
    position: sticky !important; 
    top :135px;
    background-color: #f7f7f7;
    z-index: 100; 
    padding : 0px !important;
}
.year-tab{
    position: sticky !important; 
    top :135px; 
    background-color: rgb(247, 247, 247);
    z-index: 100; 
    padding : 0px !important;

}

.day-scrollable{
    position: sticky !important;
    top: 135px;
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.month-scrollable{
    position: sticky;
    top: 182px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.year-scrollable{
    position: sticky;
    top: 145px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 99;

}
.full-height{
    height: 100%;
}
@keyframes example {
    from {background-color: white;}
    to {background-color: red;}
}
.padding {
    padding :10px;
}
.margin-bottom {
   margin-bottom: 0px;
}
.full-width{
    width: 100%;
    z-index: 1;
}
.calendar-day{
    font-size: 20px;
    padding: 0px !important;
    cursor: pointer;
}
.calendar-day-detail{
    font-size: 20px;
    border-top: 1px solid #b6b9b985;
    padding-bottom: 41px;
    padding-top: 13px;
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}
.empty{
    border-top: none!important;
    
}
.month{
    font-size: 17px;
    font-weight: 700;
}
.my-month{
    font-size: 19px;
    font-weight: 550;
}
.month-current{
    color: red;
    font-size: 17px;
    font-weight: 700;
}
.red{
    background: red;
    border-radius: 50%;
    color: white;
    /* border-width: 12px; */
    border: 10px solid #fa1100;
    margin-left: -15px;
}
.red-list{
    background: red;
    border-radius: 30px;
    color: white;
}

input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: blue;
    background-color: rgb(250, 250, 250);
    border-bottom: 1px solid #eeeeee !important;
}
input:focus {
    border-color: green
}
.input-event{
    border-bottom: 1px solid #f7f7f7 ;
    align-self : flex-start ;
    height: 20px;
}
.event-row{
    margin-bottom: 13px;
    margin-left: 0px;
    display: flex;
    align-items: flex-end;
    height: 30px;
}
.time{
    margin-right: 15px;
    padding: 0px !important;
    text-align: right;
    margin-bottom: 0px;
}

.agenda-date{
    background-color: #f7f7f7;
    font-size: 19px;
    color: black;
    position: sticky !important;
    top: 90px;
    z-index: 1;
}
.agenda{
    border-bottom: 1px solid grey;
}
.agenda-timings{
    border-right: 1px solid #efefef;
}
.agenda-timings .agenda-time{
    margin-bottom: 0px !important;
    color: #c8c8c8;
}
.title-location{
    background-color: white;
}
.agenda .title-location .title{
    margin-bottom: 0px !important;
}
.agenda .title-location .location{
    margin-bottom: 0px !important;
    color: #c8c8c8;
}
.scroll-weeks{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

}
/*.scroll-weeks:first-child {*/
    /*display: none;*/
/*}*/
.scroll-weeks::-webkit-scrollbar {
    display: none;
}
.height{
    height: 50px;
}
.week-scroll {
    display: flex;
    justify-content: space-between;
}
.tdBckgrnd{
    background-color: rgb(204, 239,252);
}
.justRed{
    background-color: red;
}
.circle {
    
  height: 9px;
  width: 9px;
  margin-top: 40px;
  margin-left: 7px;
  background-color: #bbb;
  border-radius: 50%;
  position: absolute;

}
.conditional{
    display: none;
}
.mainHeader{
    position: sticky;
    top : 63px;
    z-index: 12000
}
.mblMainHeader{
    position: sticky;
    top : 63px;
    z-index: 1000
}
.headerTitle{
    position:sticky;
    top : 63px;
    background-color : #efefef;
    z-Index : 1200;
}
.tagContainer{
    position: sticky;
    top : 110px;
    margin-top: -7px;
    background-color : #efefef;
    z-Index : 1200;
}
.divBackgrnd{
    overflow: hidden;
    background-color: #efefef;
}
/* 
mbl class */
.mblHeaderTitle{
    position:sticky;
    top : 99px;
    height: 55px;
    font-size: x-large;
    background-color : #efefef;
    z-Index : 1001;
}
.mblTagContainer{
    position: sticky;
    top : 154px;
    margin-top: -8px;
    background-color : #efefef;
    z-Index : 1200;
}
.mblDay-scrollable{
    position: sticky !important;
    top: 179px;
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.mblWeekend-sticky{
    position: sticky !important; 
    top :179px;
    background-color: #f7f7f7;
    z-index: 100; 
    padding : 0px !important;
}
.mblMonth-scrollable{
    position: sticky ;
    top: 226px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 100;

}
.mblYear-scrollable{
    position: sticky;
    top: 189px;
    /* background-color:greenyellow; */
    background-color: rgb(247, 247, 247);
    z-index: 99;

}
.mblYear-tab{
    position: sticky !important; 
    top :180px; 
    background-color: rgb(247, 247, 247);
    z-index: 100; 
    padding : 0px !important;

}
.mblCalendar-day{
    font-size: 15px;
    padding: 0px !important;
    cursor: pointer;
}
.my-curMonth{
    color:red;
    cursor: pointer;
    float: right;
    /* margin-top: 22px; */
}
.backBtn-Height{
    height: 21px;
}
.display-year{
    font-size: medium;
    color: red;
    /* margin-bottom: 0px; */
    z-index: 1201;
}


 /* h1 font sizes */
 @media screen and (max-width : 640px)
{
  .sizing
  {
    font-size: 7vw;
  }
}
